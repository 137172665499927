import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Filetypes"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icons" tierThree="Filetypes" />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          Identifying different types of files is necessary when uploading and
          hosting varying information. Stick to these icons to represent them,
          unless you're showcasing Sportscode files.
        </Paragraph>
        <DontDo
          dontText="use brands when representing files."
          doText="keep file types agnostic."
          imgFilename="icons-filetypes"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconFiletypesDesign($title: String = "Filetypes") {
    ...iconDataDesign
  }
`;
